body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  transition: all 0.3s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* https://w3generator.com/scrollbar */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
@media only screen and (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, #42596529 14%, #42596529 64%);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, #c8d1d3 14%, #c8d1d3 64%);
  }
  ::-webkit-scrollbar-track {
    /* background: #ffffff; */
    border-radius: 10px;
  }
}
